import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "insiderjobs"
    }}>{`InsiderJobs`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`InsiderJobs is a platform for companies and employees to meet based on interest in shared content. At the core there is an elasticsearch instance that indexes all available jobs to make them explorable by the employees.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p><img alt="Logical architecture" src={require("./images/InsiderJobsArch.png")} /></p>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Frontend: React/NextJS (TypeScript)`}</li>
      <li parentName="ul">{`Backend: .NET Core 6.x (C#)`}</li>
      <li parentName="ul">{`Docker & helm charts`}</li>
      <li parentName="ul">{`GitVersion to auto-version artifacts`}</li>
      <li parentName="ul">{`Azure DevOps pipeline definitions`}</li>
      <li parentName="ul">{`Keycloak as OAuth/OpenID server`}</li>
      <li parentName="ul">{`PostgreSQL for the Api Service`}</li>
      <li parentName="ul">{`Git secret to encrypt sensitive information like configuration files`}</li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TEST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PROD`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Frontend`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entry point for users`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.insiderjobs.hybrit.io/"
            }}>{`https://test.insiderjobs.hybrit.io/`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://insiderjobs.hybrit.io/"
            }}>{`https://insiderjobs.hybrit.io/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Admin console`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.auth.insiderjobs.hybrit.io/auth/"
            }}>{`https://test.auth.insiderjobs.hybrit.io/auth/`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://auth.insiderjobs.hybrit.io/auth/"
            }}>{`https://auth.insiderjobs.hybrit.io/auth/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`API`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swagger docs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://test.gateway.insiderjobs.hybrit.io/swagger/index.html"
            }}>{`https://test.gateway.insiderjobs.hybrit.io/swagger/index.html`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://gateway.insiderjobs.hybrit.io/swagger/index.html"
            }}>{`https://gateway.insiderjobs.hybrit.io/swagger/index.html`}</a></td>
        </tr>
      </tbody>
    </table>
    <p>{`The kubernetes cluster runs in Azure and is named `}<inlineCode parentName="p">{`insiderjobs-weu-new`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "service-bus"
    }}>{`Service Bus`}</h2>
    <p>{`To keep all instances updated based on user interaction with the API a service bus will send messages to update others on relevant events.`}</p>
    <h3 {...{
      "id": "topics"
    }}>{`Topics`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Consumer(s)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`insiderjobs-messages`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The topic where all messages will be sent over`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Api Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search Service, Notification Service`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "subscriptions"
    }}>{`Subscriptions`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`entity-updates`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The subscription for the Search Service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`insiderjobs-notifcations`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The subscription for the Notification Service`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <p>{`At the start we decided to use a mono-repo for this project. Based on current knowledge we might change this at a later stage.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Project`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mono-repo containing the whole project`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://dev.azure.com/hybritdev/_git/InsiderJobs"
            }}>{`https://dev.azure.com/hybritdev/_git/InsiderJobs`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <h3 {...{
      "id": "git-secret"
    }}>{`git secret`}</h3>
    <p>{`The Repository has files containing secrets (connection strings, passwords etc.). They are encrypted with git secret. Make sure that you are added to the repositories and have the ability to run `}<inlineCode parentName="p">{`git secret reveal`}</inlineCode>{`. You can see who is able to add you via `}<a parentName="p" {...{
        "href": "src-getting-started-gitsecret#show-added-people"
      }}>{`git secret whoknows`}</a>{`.`}</p>
    <h3 {...{
      "id": "frontend"
    }}>{`Frontend`}</h3>
    <ol>
      <li parentName="ol">{`Pull `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev/_git/InsiderJobs"
        }}>{`https://dev.azure.com/hybritdev/_git/InsiderJobs`}</a></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`cd frontend`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn dev`}</inlineCode></li>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "http://localhost:3000"
        }}>{`http://localhost:3000`}</a></li>
    </ol>
    <p>{`You can log in with an administrator account, you can find the username and password in the `}<a parentName="p" {...{
        "href": "https://github.com/HybrIT-dev/bouwkennis-identity-keycloak"
      }}>{`identity repository`}</a>{`. Look at the values.test.yaml file. `}</p>
    <h3 {...{
      "id": "net-apis"
    }}>{`.NET APIs`}</h3>
    <ol>
      <li parentName="ol">{`Pull `}<a parentName="li" {...{
          "href": "https://dev.azure.com/hybritdev/_git/InsiderJobs"
        }}>{`https://dev.azure.com/hybritdev/_git/InsiderJobs`}</a></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`cd backend`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`git secret reveal`}</inlineCode></li>
      <li parentName="ol">{`Open the solution (sln) in Visual Studio and add the docker-compose files to the config`}</li>
      <li parentName="ol">{`Run the docker-compose config`}</li>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "http://localhost:17409/auth/admin/master/console/"
        }}>{`http://localhost:17409/auth/admin/master/console/`}</a>{` and make an account to access the swagger`}</li>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "http://localhost:17411/swagger"
        }}>{`http://localhost:17411/swagger`}</a>{` to log in and show the swagger.`}</li>
    </ol>
    <h2 {...{
      "id": "authorisation-overview"
    }}>{`Authorisation overview`}</h2>
    <p>{`The accessibility of data and operations is restricted by a set of policies which use roles and custom claim attributes.`}</p>
    <h3 {...{
      "id": "roles"
    }}>{`Roles`}</h3>
    <p>{`The following roles are defined:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Role`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Scope`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`USER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate role. Will be automatically assigned when registering an account for a candidate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`COMPANY_OWNER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Company owner role. Will be automatically assigned when registering an account for a company owner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ADMINISTRATOR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator role. `}<strong parentName="td">{`Only assignable in Keycloak`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NOTIFICATION_SERVICE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Service account`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification service account for retrieving data from the `}<inlineCode parentName="td">{`api-service`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "custom-claim-attributes"
    }}>{`Custom claim attributes`}</h3>
    <p>{`The following attributes are added to the JWT tokens for authorisation:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`company_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User attribute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Id of the company a `}<strong parentName="td">{`Company Owner`}</strong>{` user linked to (after claim verification)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "policies"
    }}>{`Policies`}</h3>
    <p>{`The following policies are defined as follows:`}</p>
    <blockquote>
      <p parentName="blockquote">{`To learn more about their frontend usage: `}<a parentName="p" {...{
          "href": "#routes-and-menu"
        }}>{`Routes and menu`}</a>{`.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`backend name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`frontend name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Is applicable when`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Authenticated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isAuthenticated`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user is authenticated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Candidate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isCandidate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user has role `}<inlineCode parentName="td">{`USER`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CompanyOwner`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isCompanyOwner`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user has role `}<inlineCode parentName="td">{`COMPANY_OWNER`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Administrator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isAdministrator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user has role `}<inlineCode parentName="td">{`ADMINISTRATOR`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CompanyOwnerOrAdministrator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isCompanyOwnerOrAdministrator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user has role `}<inlineCode parentName="td">{`ADMINISTRATOR`}</inlineCode>{` or `}<inlineCode parentName="td">{`COMPANY_OWNER`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HasAccessToCompany`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hasAccessToCompany`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current user has role `}<inlineCode parentName="td">{`ADMINISTRATOR`}</inlineCode>{` or `}<inlineCode parentName="td">{`COMPANY_OWNER`}</inlineCode>{` and claim attribute `}<inlineCode parentName="td">{`company_id`}</inlineCode>{` matches the company ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NotificationService`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NotificationService`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The current `}<em parentName="td">{`user`}</em>{` has role `}<inlineCode parentName="td">{`NOTIFICATION_SERVICE`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "restrictions"
    }}>{`Restrictions`}</h3>
    <p>{`For the api services and the frontend the following restrictions apply.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The operation (data) is either indicated to be restricted by one of the above-mentioned policies or with: `}</p>
      <ul parentName="blockquote">
        <li parentName="ul"><inlineCode parentName="li">{`NONE`}</inlineCode>{`: No restrictions`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`N/A`}</inlineCode>{`: Operation not available`}</li>
      </ul>
    </blockquote>
    <h4 {...{
      "id": "api-service"
    }}>{`API Service`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Domain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Create`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read all`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read single`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Update`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Article`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Colleague`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company - logo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company - activate`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company - deactivate`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company - import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator (get example)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CompanyClaim`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CompanyLocation`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CorporateCulture`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Department`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Job`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`JobTitle`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - Account`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - Avatar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - Profile`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - Saved Searches`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - Saved Jobs`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Organisation Type`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Service Tag`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Statistics - Company`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Summary - Company`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NotificationService`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Summary - Job`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NotificationService`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Summary - User`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NotificationService`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "search-service"
    }}>{`Search Service`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Domain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Create`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read all`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read single`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Update`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search Jobs`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "notification-service"
    }}>{`Notification Service`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Domain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Create`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read all`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Read single`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Update`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Admin - messages`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Company - messages`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`User - messages`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "routes-and-menu"
    }}>{`Routes and menu`}</h2>
    <p>{`The frontend uses the defined `}<a parentName="p" {...{
        "href": "#policies"
      }}>{`Policies`}</a>{` for the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Routing`}</strong>{`, the authentication for frontend pages`}</li>
      <li parentName="ul"><strong parentName="li">{`Menu item visibility`}</strong>{`, whether users can see a page in the sidebar menu`}</li>
    </ul>
    <p>{`Both these items are managed in the routing belonging to each page.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`routes.tsx`}</inlineCode>{` exports all routes and defines some generic routes`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`modules/*`}</inlineCode>{` contains folders for the respective page domains`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`routingUtils.tsx`}</inlineCode>{` enforces the policies based on the rules defined in the `}<inlineCode parentName="li">{`routes`}</inlineCode>{` file.`}</li>
    </ul>
    <h3 {...{
      "id": "building-a-route"
    }}>{`Building a route`}</h3>
    <p>{`Below is an example of two routes, with and without an assigned menu item:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export const profileRoutes: InsiderJobsRoute[] = [
  {
    // mandatory: route information
    path: '/profile', // path
    element: <Profile />, // the page element to return
    policy: SecurityPolicy.Authenticated, // viewing policy

    // *optional: menu item associated with the route
    menu: {
      section: MenuSection.Platform, // menu section to place link
      
      scopes: [ // the Roles able to see the items in the menu
        MenuScope.Candidate,
        MenuScope.CompanyOwner,
        MenuScope.CompanyAdministrator,
        MenuScope.Administrator,
      ],
      // name and (*optional) icon
      item: {
        label: 'menu.my-profile',
        icon: <img src={'/icons/home_icon.svg'} alt="Profile" />,
      },
    },
    children: childRoutes, // (*optional) any child routes
  },
  {
    path: '/saved',
    element: <SavedItems />,
    policy: SecurityPolicy.Candidate,
  }
];
`}</code></pre>
    <p>{`Enums:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`SecurityPolicy`}</inlineCode>{` defines whether someone can `}<strong parentName="li">{`visit`}</strong>{` a route. This doesn't block visibility of an item in the menu. Uses backend naming conventions`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MenuSection`}</inlineCode>{` defines the various sections in the menu`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MenuScope`}</inlineCode>{` checks whether a user can see the route `}<strong parentName="li">{`in the menu`}</strong>{`. This does not define whether they can visit the page through other means`}</li>
    </ul>
    <p>{`Menu scope items:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Anonymous`}</inlineCode>{` - everyone`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Candidate`}</inlineCode>{` - InsiderJobs candidates`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CompanyOwner`}</inlineCode>{` - company owner without a companyId`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CompanyAdministrator`}</inlineCode>{` - fully registered company owner with company`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Administrator`}</inlineCode>{` - administrators only`}</li>
    </ul>
    <h3 {...{
      "id": "currently-assigned-routes"
    }}>{`Currently assigned routes`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Note: signing in uses a Keycloak page and is not included in the frontend routes.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Route`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SecurityPolicy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`MenuScope`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Page functionality`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Homepage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/account/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Basic account details edit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/articles`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Article overview`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/articles/new`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/articles/:articleId/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/claim-company`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claiming company`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/claim-verification`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verifying a claim`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwner`}<br />{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of companies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/colleagues`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of colleagues`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/colleagues/new`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/colleagues/:colleagueId/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/departments/new`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyOwnerOrAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/:companyId/jobs/:jobId/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/my-company`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Own company page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/my-company/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HasAccessToCompany`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CompanyAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit own company`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/companies/new`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/compare`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Comparing jobs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/help`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anonymous`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General help page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/jobtitles/new`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/messages`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}<br />{`CompanyAdministrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message system`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/profile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}<br />{`CompanyAdministrator`}<br />{`CompanyOwner`}<br />{`Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/profile/edit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Editing candidate profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/registration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New candidates profile setup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/saved`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Candidate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Saved jobs and searches`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/search`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`All`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search results page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/settings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authenticated`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`General settings page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/signup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anonymous`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creating a new account`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "re-indexing-companies-for-search"
    }}>{`Re-indexing companies for search`}</h2>
    <p>{`Companies and jobs are indexed automatically in ElasticSearch to accommodate searching jobs. Sometimes the complete index needs to be refreshed.
There are 3 cases that require re-indexing:`}</p>
    <ul>
      <li parentName="ul">{`The index mapping has been changed; `}</li>
      <li parentName="ul">{`Something went wrong during the automatic process and the index has gotten corrupt; `}</li>
      <li parentName="ul">{`Something went wrong during the automatic process and something (a company, a job or the location of a job) is missing.`}</li>
    </ul>
    <p>{`All 3 cases require a different strategy to fix the issue. For cases 2 and 3 there might be a bug that needs to be fixed first.`}</p>
    <h3 {...{
      "id": "1-re-index-when-the-mapping-has-been-changed"
    }}>{`1. Re-index when the mapping has been changed`}</h3>
    <p>{`When the mapping has been changed, the index needs to be recreated. Follow these steps:`}</p>
    <ul>
      <li parentName="ul">{`Login to elastic cloud (`}<a parentName="li" {...{
          "href": "https://cloud.elastic.co/login?redirectTo=%2Fdeployments"
        }}>{`https://cloud.elastic.co/login?redirectTo=%2Fdeployments`}</a>{`) using the credentials for user `}<strong parentName="li"><em parentName="strong">{`support+insiderjobs-elastic-cloud@hybrit.org`}</em></strong>{` from last pass Shared Cloud. `}</li>
      <li parentName="ul">{`Select the related deployment environment (`}<em parentName="li">{`-prod or `}</em>{`-test)`}</li>
      <li parentName="ul">{`Open `}<strong parentName="li"><em parentName="strong">{`Kibana`}</em></strong>{` from the deployment `}</li>
      <li parentName="ul">{`In `}<strong parentName="li"><em parentName="strong">{`Kibana`}</em></strong>{` go to `}<strong parentName="li"><em parentName="strong">{`Stack Management`}</em></strong>{` (Menu -> Management -> Stack Management)`}</li>
      <li parentName="ul">{`In `}<strong parentName="li"><em parentName="strong">{`Stack Management`}</em></strong>{` go to `}<strong parentName="li"><em parentName="strong">{`Index Management`}</em></strong>{` (Menu -> Data -> Index Management)`}</li>
      <li parentName="ul">{`In `}<strong parentName="li"><em parentName="strong">{`Index Management`}</em></strong>{` select the index `}<strong parentName="li"><em parentName="strong">{`company`}</em></strong>{` (either by clicking on it or the checkbox at the beginning of the line)`}</li>
      <li parentName="ul">{`In the dropdown menu `}<strong parentName="li"><em parentName="strong">{`Manage index`}</em></strong>{` select `}<strong parentName="li"><em parentName="strong">{`Delete index`}</em></strong></li>
      <li parentName="ul">{`Restart the `}<strong parentName="li"><em parentName="strong">{`Search service`}</em></strong>{` (either by redeploying or deleting the kubernetes pod)`}</li>
      <li parentName="ul">{`Trigger the reindexing (as Administrator) from the `}<strong parentName="li"><em parentName="strong">{`API service`}</em></strong>{` (GET `}<inlineCode parentName="li">{`/api/index/companies`}</inlineCode>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "2-re-index-when-the-index-is-corrupted"
    }}>{`2. Re-index when the index is corrupted`}</h3>
    <p>{`When the index is corrupted, all documents need to be re-indexed. Follow these steps:`}</p>
    <ul>
      <li parentName="ul">{`Delete all companies from the index (as Administrator) from the `}<strong parentName="li"><em parentName="strong">{`Search service`}</em></strong>{` (DELETE `}<inlineCode parentName="li">{`/api/search/companies`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Trigger the reindexing (as Administrator) from the `}<strong parentName="li"><em parentName="strong">{`API service`}</em></strong>{` (GET `}<inlineCode parentName="li">{`/api/index/companies`}</inlineCode>{`)`}</li>
    </ul>
    <h3 {...{
      "id": "3-re-index-when-something-is-missing-in-the-index"
    }}>{`3. Re-index when something is missing in the index`}</h3>
    <p>{`When something is missing in the index which is available in de API database trigger the reindexing (as Administrator) from the `}<strong parentName="p"><em parentName="strong">{`API service`}</em></strong>{` (GET `}<inlineCode parentName="p">{`/api/index/companies`}</inlineCode>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      